import firebase from "firebase/app"
import uuid from "uuid/v4"

export async function firebaseUploadAndGetUrl(
  value: File | Blob,
  src: "docx" | "certificate" | "cvs"
): Promise<string> {
  const storage = firebase.storage()

  let url = ""

  const filename = src == "cvs" ? `${uuid()}` : `${uuid()}.pdf`

  await storage.ref(`${src}/${filename}`).put(value)

  url = `${src}/${filename}`

  return url
}
