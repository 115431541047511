import firebase from "firebase/app"
import { Formik } from "formik"
import { without } from "lodash"
import React, { useState } from "react"
import { Button, Col, Form, Row, Spinner } from "react-bootstrap"
import * as Yup from "yup"
import { Footer } from "../components/Footer"
import { Header } from "../components/Header"
import { teacherFormsCollectionRef } from "../config/collectionsRef"
import "../css"
import "../scss/index.scss"
import locales from "../translate/locales"
import { firebaseUploadAndGetUrl } from "../utils/firebaseUploadAndGetUrl"

const TeacherForm = (props: any) => {
  const locale = props.pageContext.locale
  const texts = locales[locale as "en"]
  const [cvFile, setCvFile] = useState()

  const [loading, setLoading] = useState<boolean>(false)

  const translate = texts.becomeATeacherForm
  const category = texts.categories

  const schema = Yup.object({
    name: Yup.string().required(),
    lastName: Yup.string().required(),
    // Nationality: Yup.string().required(),
    countryOfResidence: Yup.string().required(),
    city: Yup.string().required(),
    mobile: Yup.number().required(),
    dateOfBirth: Yup.date().required(),

    degree: Yup.string().required(),

    generalSpecialization: Yup.string().required(),
    specialization: Yup.string().required(),

    mainAreas: Yup.array().required(),
    yearsOfExperience: Yup.string().required(),
    mostTrainings: Yup.array().required(),
    languages: Yup.array().required(),
    additionalServices: Yup.array().required(),
    subscribe: Yup.boolean().required(),
  })

  async function firebaseSubmit(values: any) {
    setLoading(true)
    if (cvFile == undefined) {
      alert(`Hi Mr.${values.name + "" + values.lastName} Pleas Upload Your Cv`)
    }

    const cv = await firebaseUploadAndGetUrl(cvFile, "cvs")

    const storageUrl = await firebase
      .storage()
      .ref(cv)
      .getDownloadURL()
      .then((e) => {
        return e
      })

    teacherFormsCollectionRef
      .add({
        submittedDate: new Date(),

        ...values,
        cvDownloadUrl: storageUrl,
      })
      .then(() => {
        setLoading(false)
        alert(`Hi Mr.${values.name + "" + values.lastName} Your Enroll Success`)
      })
  }

  const requiredStyle = { marginLeft: 2, marginRight: 2, color: "red" }
  const formCheckStyle = { color: "#5C5C5C", marginTop: 6, marginBottom: 6 }

  return (
    <div>
      <Header lang={locale} />

      <div
        className="page-title bg_cover mt-80 pt-140 pb-140"
        style={{ backgroundImage: `url(/images/pexels-photo-3183150.jpeg)` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="page-title-item text-center">
                <h3 className="title">{translate.welcomeToOurTeam}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="access-area pt-100 pb-120">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="access-content">
                <div className="access-mission">
                  <p style={{ color: "#5C5C5C" }}>{translate.intro}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <Formik
              validationSchema={schema}
              onSubmit={(values) => firebaseSubmit(values)}
              initialValues={{
                name: "",
                lastName: "",
                nationality: "",
                countryOfResidence: "",
                email: "",
                city: "",
                mobile: "",
                dateOfBirth: undefined,

                degree: "",
                generalSpecialization: "",
                specialization: "",
                mainAreas: [],
                yearsOfExperience: "",
                mostTrainings: [],
                languages: [],
                additionalServices: [],
                cv: "",
                subscribe: false,
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => {
                function checkedToFormikArrayValue(
                  filedValueArray: string[],
                  filed: string,
                  check: any,
                  value: string
                ) {
                  const selectedBefore = filedValueArray.find(
                    (e) => e === value
                  )

                  if (check && selectedBefore == undefined) {
                    setFieldValue(filed, [...filedValueArray, value])
                  } else {
                    const removedValue = without(filedValueArray, value)

                    setFieldValue(filed, removedValue)
                  }
                }

                return (
                  <Form>
                    <Form.Group as={Row}>
                      <Form.Label style={{ color: "#5C5C5C" }} column sm={8}>
                        <b>{translate.name}</b> <b style={requiredStyle}>*</b>
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isValid={touched.name && !errors.name}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label style={{ color: "#5C5C5C" }} column sm={8}>
                        <b> {translate.LastName}</b>
                        <b style={requiredStyle}>*</b>
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          placeholder="Last Name"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          isValid={touched.lastName && !errors.lastName}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label style={{ color: "#5C5C5C" }} column sm={8}>
                        <b>{texts.becomeATeacherForm.email}</b>{" "}
                        <b style={requiredStyle}>*</b>
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          placeholder="Email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isValid={touched.email && !errors.email}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label style={{ color: "#5C5C5C" }} column sm={8}>
                        <b>{translate.Nationality}</b>{" "}
                        <b style={requiredStyle}>*</b>
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="nationality"
                          value={values.nationality}
                          onChange={handleChange}
                          isValid={touched.nationality && !errors.nationality}
                        ></Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label style={{ color: "#5C5C5C" }} column sm={8}>
                        <b>{translate.countryOfResidence}</b>
                        <b style={requiredStyle}>*</b>
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="countryOfResidence"
                          value={values.countryOfResidence}
                          onChange={handleChange}
                          isValid={
                            touched.countryOfResidence &&
                            !errors.countryOfResidence
                          }
                        ></Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label style={{ color: "#5C5C5C" }} column sm={8}>
                        <b>{translate.city}</b> <b style={requiredStyle}>*</b>
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="city"
                          value={values.city}
                          onChange={handleChange}
                          isValid={touched.city && !errors.city}
                        ></Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label style={{ color: "#5C5C5C" }} column sm={8}>
                        <b>{translate.mobile}</b> <b style={requiredStyle}>*</b>
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="mobile"
                          value={values.mobile}
                          onChange={handleChange}
                          isValid={touched.mobile && !errors.mobile}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label style={{ color: "#5C5C5C" }} column sm={8}>
                        <b>{translate.dateOfBirth}</b>{" "}
                        <b style={requiredStyle}>*</b>
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="date"
                          name="dateOfBirth"
                          value={values.dateOfBirth}
                          onChange={handleChange}
                          isValid={touched.dateOfBirth && !errors.dateOfBirth}
                        />
                      </Col>
                    </Form.Group>

                    <fieldset>
                      <Form.Group as={Row}>
                        <Form.Label
                          style={{ color: "#5C5C5C" }}
                          as="legend"
                          column
                          sm={8}
                        >
                          <b>{translate.degree}</b>{" "}
                          <b style={requiredStyle}>*</b>
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={translate.phd}
                            name="degree"
                            value={values.degree}
                            onChange={() =>
                              setFieldValue("degree", translate.phd)
                            }
                            checked={values.degree === translate.phd}
                            id="formHorizontalRadios1"
                          />
                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={translate.ma}
                            name="degree"
                            value={values.degree}
                            onChange={() =>
                              setFieldValue("degree", translate.ma)
                            }
                            id="formHorizontalRadios2"
                            checked={values.degree === translate.ma}
                          />
                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={translate.ba}
                            name="degree"
                            value={values.degree}
                            onChange={() =>
                              setFieldValue("degree", translate.ba)
                            }
                            checked={values.degree === translate.ba}
                            id="formHorizontalRadios3"
                          />
                        </Col>
                      </Form.Group>
                    </fieldset>

                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label style={{ color: "#5C5C5C" }}>
                        <b>{translate.generalSpecialization}</b>{" "}
                        <b style={requiredStyle}>*</b>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        name="generalSpecialization"
                        value={values.generalSpecialization}
                        onChange={handleChange}
                        isValid={
                          touched.generalSpecialization &&
                          !errors.generalSpecialization
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label style={{ color: "#5C5C5C" }}>
                        {" "}
                        <b>{translate.specialization}</b>{" "}
                        <b style={requiredStyle}>*</b>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        name="specialization"
                        value={values.specialization}
                        onChange={handleChange}
                        isValid={
                          touched.specialization && !errors.specialization
                        }
                      />
                    </Form.Group>

                    <fieldset>
                      <Form.Group as={Row}>
                        <Form.Label
                          style={{ color: "#5C5C5C" }}
                          as="legend"
                          column
                          sm={8}
                        >
                          <b>{translate.mainAreas}</b>{" "}
                          <b style={requiredStyle}>*</b>
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={category.HumanResourceManagement}
                            name="mainAreas"
                            onChange={(e: any) => {
                              checkedToFormikArrayValue(
                                values.mainAreas,
                                "mainAreas",
                                e,
                                category.HumanResourceManagement
                              )
                            }}
                            id="formHorizontalRadios1"
                          />
                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={category.safetyAndSecurity}
                            name="mainAreas"
                            onChange={(e: any) => {
                              checkedToFormikArrayValue(
                                values.mainAreas,
                                "mainAreas",
                                e,
                                category.safetyAndSecurity
                              )
                            }}
                            id="formHorizontalRadios2"
                          />
                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={category.institutionalExcellenceAndQuality}
                            name="mainAreas"
                            onChange={(e: any) => {
                              checkedToFormikArrayValue(
                                values.mainAreas,
                                "mainAreas",
                                e,
                                category.institutionalExcellenceAndQuality
                              )
                            }}
                            id="formHorizontalRadios3"
                          />

                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={category.mediaAndPublicRelations}
                            name="mainAreas"
                            onChange={(e: any) => {
                              checkedToFormikArrayValue(
                                values.mainAreas,
                                "mainAreas",
                                e,
                                category.mediaAndPublicRelations
                              )
                            }}
                            id="formHorizontalRadios3"
                          />

                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={category.salesAndMarketing}
                            name="mainAreas"
                            onChange={(e: any) => {
                              checkedToFormikArrayValue(
                                values.mainAreas,
                                "mainAreas",
                                e,
                                category.salesAndMarketing
                              )
                            }}
                            id="formHorizontalRadios3"
                          />

                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={category.HumanResourceManagement}
                            name="mainAreas"
                            onChange={(e: any) => {
                              checkedToFormikArrayValue(
                                values.mainAreas,
                                "mainAreas",
                                e,
                                category.HumanResourceManagement
                              )
                            }}
                            id="formHorizontalRadios3"
                          />

                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={category.institutionalExcellenceAndQuality}
                            name="mainAreas"
                            onChange={(e: any) => {
                              checkedToFormikArrayValue(
                                values.mainAreas,
                                "mainAreas",
                                e,
                                category.institutionalExcellenceAndQuality
                              )
                            }}
                            id="formHorizontalRadios3"
                          />

                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={category.lawAndContracts}
                            name="mainAreas"
                            onChange={(e: any) => {
                              checkedToFormikArrayValue(
                                values.mainAreas,
                                "mainAreas",
                                e,
                                category.lawAndContracts
                              )
                            }}
                            id="formHorizontalRadios3"
                          />

                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={category.engineeringAndEnergy}
                            name="mainAreas"
                            onChange={(e: any) => {
                              checkedToFormikArrayValue(
                                values.mainAreas,
                                "mainAreas",
                                e,
                                category.engineeringAndEnergy
                              )
                            }}
                            id="formHorizontalRadios3"
                          />

                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={category.universityPrograms}
                            name="mainAreas"
                            onChange={(e: any) => {
                              checkedToFormikArrayValue(
                                values.mainAreas,
                                "mainAreas",
                                e,
                                category.universityPrograms
                              )
                            }}
                            id="formHorizontalRadios3"
                          />

                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={category.informationTechnology}
                            name="mainAreas"
                            onChange={(e: any) => {
                              checkedToFormikArrayValue(
                                values.mainAreas,
                                "mainAreas",
                                e,
                                category.informationTechnology
                              )
                            }}
                            id="formHorizontalRadios3"
                          />

                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={category.oilAndGasSector}
                            name="mainAreas"
                            onChange={(e: any) => {
                              checkedToFormikArrayValue(
                                values.mainAreas,
                                "mainAreas",
                                e,
                                category.oilAndGasSector
                              )
                            }}
                            id="formHorizontalRadios3"
                          />
                        </Col>
                      </Form.Group>
                    </fieldset>

                    <fieldset>
                      <Form.Group as={Row}>
                        <Form.Label
                          style={{ color: "#5C5C5C" }}
                          as="legend"
                          column
                          sm={8}
                        >
                          <b> {translate.yearsOfExperience}</b>
                          <b style={requiredStyle}>*</b>
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label="No training experience"
                            name="yearsOfExperience"
                            id="formHorizontalRadios1"
                            onChange={() =>
                              setFieldValue(
                                "yearsOfExperience",
                                "No training experience"
                              )
                            }
                            checked={
                              values.yearsOfExperience ===
                              "No training experience"
                            }
                          />
                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={`1 - 3 ${translate.years}`}
                            name="yearsOfExperience"
                            onChange={() =>
                              setFieldValue(
                                "yearsOfExperience",
                                `1 - 3 ${translate.years}`
                              )
                            }
                            checked={
                              values.yearsOfExperience ===
                              `1 - 3 ${translate.years}`
                            }
                          />
                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={`4 - 6 ${translate.years}`}
                            name="yearsOfExperience"
                            onChange={() =>
                              setFieldValue(
                                "yearsOfExperience",
                                `4 - 6 ${translate.years}`
                              )
                            }
                            checked={
                              values.yearsOfExperience ===
                              `4 - 6 ${translate.years}`
                            }
                          />

                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={`7 - 12 ${translate.years}`}
                            name="yearsOfExperience"
                            onChange={() =>
                              setFieldValue(
                                "yearsOfExperience",
                                `7 - 12 ${translate.years}`
                              )
                            }
                            checked={
                              values.yearsOfExperience ===
                              `7 - 12 ${translate.years}`
                            }
                          />

                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={`12  ${translate.years}`}
                            name="yearsOfExperience"
                            onChange={() =>
                              setFieldValue(
                                "yearsOfExperience",
                                `12  ${translate.years}`
                              )
                            }
                            checked={
                              values.yearsOfExperience ===
                              `12  ${translate.years}`
                            }
                          />
                        </Col>
                      </Form.Group>
                    </fieldset>

                    <fieldset>
                      <Form.Group as={Row}>
                        <Form.Label
                          style={{ color: "#5C5C5C" }}
                          as="legend"
                          column
                          sm={8}
                        >
                          <b> {translate.sectorTraining}</b>{" "}
                          <b style={requiredStyle}>*</b>
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={translate.governmentSector}
                            id="formHorizontalRadios1"
                            name="mostTrainings"
                            onChange={(e: any) => {
                              checkedToFormikArrayValue(
                                values.mostTrainings,
                                "mostTrainings",
                                e,
                                translate.governmentSector
                              )
                            }}
                          />
                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={translate.privateSector}
                            id="formHorizontalRadios2"
                            name="mostTrainings"
                            onChange={(e: any) => {
                              checkedToFormikArrayValue(
                                values.mostTrainings,
                                "mostTrainings",
                                e,
                                translate.privateSector
                              )
                            }}
                          />
                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={translate.charitableSector}
                            id="formHorizontalRadios3"
                            name="mostTrainings"
                            onChange={(e: any) => {
                              checkedToFormikArrayValue(
                                values.mostTrainings,
                                "mostTrainings",
                                e,
                                translate.charitableSector
                              )
                            }}
                          />
                        </Col>
                      </Form.Group>
                    </fieldset>

                    <fieldset>
                      <Form.Group as={Row}>
                        <Form.Label
                          style={{ color: "#5C5C5C" }}
                          as="legend"
                          column
                          sm={8}
                        >
                          <b>{translate.practiceProfessionalLang}</b>
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={translate.ar}
                            name="languages"
                            onChange={(e: any) => {
                              checkedToFormikArrayValue(
                                values.languages,
                                "languages",
                                e,
                                translate.ar
                              )
                            }}
                            id="formHorizontalRadios1"
                          />
                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={translate.en}
                            name="languages"
                            onChange={(e: any) => {
                              checkedToFormikArrayValue(
                                values.languages,
                                "languages",
                                e,
                                translate.en
                              )
                            }}
                            id="formHorizontalRadios2"
                          />
                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={translate.fr}
                            name="languages"
                            onChange={(e: any) => {
                              checkedToFormikArrayValue(
                                values.languages,
                                "languages",
                                e,
                                translate.fr
                              )
                            }}
                            id="formHorizontalRadios3"
                          />
                        </Col>
                      </Form.Group>
                    </fieldset>

                    <fieldset>
                      <Form.Group as={Row}>
                        <Form.Label
                          style={{ color: "#5C5C5C" }}
                          as="legend"
                          column
                          sm={8}
                        >
                          <b>{translate.additionalServices}</b>{" "}
                          <b style={requiredStyle}>*</b>
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={translate.trainingBags}
                            name="additionalServices"
                            onChange={(e: any) => {
                              checkedToFormikArrayValue(
                                values.additionalServices,
                                "additionalServices",
                                e,
                                translate.trainingBags
                              )
                            }}
                            id="formHorizontalRadios1"
                          />
                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={translate.onlineTraining}
                            onChange={(e: any) => {
                              checkedToFormikArrayValue(
                                values.additionalServices,
                                "additionalServices",
                                e,
                                translate.onlineTraining
                              )
                            }}
                            id="formHorizontalRadios2"
                          />
                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={translate.trainingNeedsAnalysis}
                            onChange={(e: any) => {
                              checkedToFormikArrayValue(
                                values.additionalServices,
                                "additionalServices",
                                e,
                                translate.trainingNeedsAnalysis
                              )
                            }}
                            id="formHorizontalRadios3"
                          />

                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={translate.consulting}
                            onChange={(e: any) => {
                              checkedToFormikArrayValue(
                                values.additionalServices,
                                "additionalServices",
                                e,
                                translate.consulting
                              )
                            }}
                            id="formHorizontalRadios3"
                          />

                          <Form.Check
                            style={formCheckStyle}
                            type="checkbox"
                            label={translate.personalGuidance}
                            onChange={(e: any) => {
                              checkedToFormikArrayValue(
                                values.additionalServices,
                                "additionalServices",
                                e,
                                translate.personalGuidance
                              )
                            }}
                            id="formHorizontalRadios3"
                          />
                        </Col>
                      </Form.Group>
                    </fieldset>

                    <Form.Group as={Row}>
                      <Form.Label
                        style={{ color: "#5C5C5C" }}
                        as="legend"
                        column
                        sm={8}
                      >
                        <b>{translate.cv} </b>
                        <b style={requiredStyle}>*</b>
                      </Form.Label>
                      <input
                        style={{ color: "#5C5C5C" }}
                        type="file"
                        onChange={(e) => {
                          if (e.target.files) {
                            setCvFile(e.target.files[0] as any)
                          }

                          e.target.value = ""
                        }}
                      />
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Col sm={10}>
                        <Form.Check
                          style={{ color: "#5C5C5C" }}
                          type="checkbox"
                          label={translate.sub}
                          onChange={handleChange}
                          name="subscribe"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Button
                        onClick={() => {
                          handleSubmit()
                        }}
                        style={{ width: "90%" }}
                      >
                        {loading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : null}

                        {translate.Submit}
                      </Button>
                    </Form.Group>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      </section>

      <Footer lang={locale} />
    </div>
  )
}

export default TeacherForm
